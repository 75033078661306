<template>
  <v-app>
    <v-system-bar>
      <v-icon icon="mdi-wifi-strength-4"></v-icon>
      <v-icon icon="mdi-signal" class="ms-2"></v-icon>
      <v-icon icon="mdi-battery" class="ms-2"></v-icon>
      <span class="ms-2">{{ currentTime }}</span>
    </v-system-bar>
    <v-main class="mt-6">
      <v-container >
        <v-row class="d-flex justify-center">
          <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="6" xxl="5" class="d-none d-lg-block mb-6">
            <ProfileBanner class="mb-6"/>
            <ProjectExperience/>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" xxl="3">
            <ProfileBanner class="d-block d-lg-none mb-6"/>
            <EducationExperience class="mb-6"/>
            <WorkExperience class="mb-6"/>
            <ExpertiseExperience class="mb-6"/>
            <div class="d-block d-lg-none">
              <ProjectExperience/>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-footer class="bg-dark text-center d-flex flex-column mt-6">
        <v-row class="pt-0 mt-1" justify="center">
          <v-col cols="12" xs="12" sm="12" md="8" lg="7" xl="6" xxl="5">
            Thank you for visiting my portfolio! Your interest is sincerely appreciated and I hope that you found value in exploring my work as a software engineer. If you have any inquiries or feedback, I would be delighted to hear from you. Once again, thank you for taking the time to browse through my portfolio.
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="mt-5 mb-5">
          Copyright © {{ new Date().getFullYear() }} — <strong v-if="profile">{{ profile.firstName }} {{ profile.lastName }}</strong>. All rights reserved.
        </div>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import WorkExperience from "./components/WorkExperience.vue";
import EducationExperience from "./components/EducationExperience.vue";
import ProjectExperience from "./components/ProjectExperience.vue";
import ProfileBanner from "./components/ProfileBanner.vue";
import ExpertiseExperience from "./components/ExpertiseExperience.vue";
export default {
  name: 'App',
  components: {
    WorkExperience,
    EducationExperience,
    ProjectExperience,
    ProfileBanner,
    ExpertiseExperience
  },
  data: () => ({
    currentTime: null,
  }),
  async mounted() {
    this.setCurrentTime();
    setInterval(() => {
      this.setCurrentTime();
    }, 1000);
  },
  methods: {
    setCurrentTime() {
      const now = new Date();
      const options = {hour: 'numeric', minute: 'numeric', hour12: false};
      const timeString = now.toLocaleTimeString([], options);
      this.currentTime = timeString;
    },
  },
}
</script>
<style>
  a {
      all: unset;
      cursor: pointer;
      color: rgb(var(--v-theme-primary)) !important;
  }

  .project-display-image {
    width: 150px;
    height: calc(calc(150px / 16) * 9);
    background-size: cover;
    background-position: center;
  }

  .project-display-content {
    width: 100%;
    min-height: calc(calc(150px / 16) * 9);
  }

  .profile-picture {
    width: 100%;
    border-radius: 4px;
  }

  .certification-logo {
    width: 55px;
    height: 55px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
  }

  .certification-border {
    border: 1px solid #212121;
  }

  .icon-hover {
    color: #E2E2E2 !important;
  }

  .icon-hover:hover {
    cursor: pointer;
    color: rgb(var(--v-theme-primary)) !important;
    transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -webkit-transition: color .2s ease-in-out;
  }

  .carousel-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .carousel-video {
    width: 100%;
    height: 100%;
  }

  .interest-thumbnail {
    height: 250px;
    width: 100%;
    background-position: center;
    background-size: cover;
  }

  .v-application .v-card .v-expansion-panel {
    box-shadow: 0 0 3px rgb(0 0 0 / 10%) !important;
    -webkit-box-shadow: 0 0 3px rgb(0 0 0 / 10%) !important;
  }

</style>

<template>
    <v-card class="rounded-xl">
        <v-card class="rounded-0" image="https://media.licdn.com/dms/image/D4E16AQGFLqv5HfZkhw/profile-displaybackgroundimage-shrink_350_1400/0/1682935498204?e=1715817600&v=beta&t=sDC7UIIvnbwQ93ENeFHtwY29JAFZPwzGPEtv5HWW2nI">
            <v-row class="d-flex space-between">
                <v-col cols="5" xs="4" sm="3" md="3" v-if="profilePictureSource?.length">
                    <v-img class="ma-4 rounded-pill" style="border: 2px solid white" :src="profilePictureSource"></v-img>
                </v-col>
                <v-col class="d-flex flex-column justify-space-between mb-4">
                    <v-fade-transition appear>
                    <div class="d-flex ma-4 ms-0 justify-end" v-if="profile?.socials && profile.socials.length > 0">
                      <a v-for="social in profile.socials" :key="social.provider" :href="social.href" target="_blank" @click="logAnalyticsEvent('visitSocial', {
                        social: social.provider,
                        visitedAt: new Date().toISOString()
                      })"><v-icon v-if="social.icon" class="mr-1 text-white" :icon="social.icon" size="30"></v-icon></a>
                    </div>
                  </v-fade-transition>
                  <v-fade-transition appear>
                      <v-dialog transition="dialog-top-transition" width="auto">
                        <template v-slot:activator="{ props }">
                          <v-fade-transition appear>
                            <div class="d-flex ma-4 mb-0 ms-0 justify-end">
                                <v-btn prepend-icon="mdi-email-plus-outline" v-bind="props" variant="tonal" color="white" style="width: fit-content" @click="openContactMeForm()">
                                    Contact me
                                </v-btn>      
                            </div>

                          </v-fade-transition>
                        </template>
                        <template v-slot:default="{ isActive }">
                          <v-card>
                            <v-toolbar
                              color="primary"
                              title="Contact me"
                            ></v-toolbar>
                            <v-sheet width="350" class="mx-auto pa-3">
                              <v-form>
                                <v-text-field
                                  color="primary"
                                  v-model="contact.name"
                                  label="Your name"
                                  variant="outlined"
                                ></v-text-field>
                                <v-text-field
                                  color="primary"
                                  v-model="contact.subject"
                                  label="Subject"
                                  variant="outlined"
                                ></v-text-field>
                                <v-textarea
                                  color="primary"
                                  v-model="contact.content"
                                  label="Question or feedback"
                                  variant="outlined"
                                ></v-textarea>
                                <v-btn @click="() => {
                                  sendMail();
                                  isActive.value = false;
                                }" block color="primary">Send Email</v-btn>
                              </v-form>
                            </v-sheet>
                          </v-card>
                        </template>
                      </v-dialog>   
                  </v-fade-transition>
                </v-col>

            </v-row>
        </v-card>
        <v-card class="rounded-0">
            <v-card-text>
                <v-fade-transition appear>
                    <h1 v-if="profile?.firstName || profile?.lastName" class="text-center text-sm-left text-md-left text-lg-left text-xl-left">{{ profile.firstName }} {{ profile.lastName }}</h1>
                  </v-fade-transition>
                  <v-fade-transition appear>
                    <h3 v-if="profile?.headlines && profile.headlines.length > 0" class="text-center text-sm-left text-md-left text-lg-left text-xl-left font-weight-light text-uppercase pt-2">{{ profile.headlines[0]?.title }}</h3>
                  </v-fade-transition>
                  <v-fade-transition appear>
                    <div class="mt-5" v-if="profile?.headlines && profile.headlines.length > 0">
                      <div v-for="headline in profile.headlines" :key="headline.title" class="d-flex mt-1 justify-start">
                        <p>
                          <span v-if="headline.icon" class="font-weight-light text-left text-sm-left text-md-left text-lg-left text-xl-left">
                            <v-icon :icon="headline.icon" class="mr-1"></v-icon>
                          </span>
                          <span v-if="headline.title">
                            {{ headline.title }}
                          </span>
                          <span v-if="headline.title && headline?.target?.name" class="font-weight-medium">
                           @ 
                            <a v-if="headline?.target?.href" :href="headline.target.href" target="_blank">{{ headline.target.name }}</a>
                            <span v-else>{{ headline.target.name }}</span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </v-fade-transition>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { doc, onSnapshot } from "firebase/firestore"; 
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
name: 'ProfileBanner',
data: () => ({
    profilePictureSource: null,
    profile: null,
    analytics: null,
    contact: {},
}),
async mounted() {
    this.analytics = getAnalytics();

    //Load profilepicture
    var fileRef = storageRef(this.$storage, '/profile_picture.jpg');
    getDownloadURL(fileRef).then(url => {
      this.profilePictureSource = url;
    });

    onSnapshot(doc(this.$firestore, "profile", "main"), (querySnapshot) => { this.profile = querySnapshot.data(); });
},
methods: {
    logAnalyticsEvent(eventName, eventData) {
      logEvent(this.analytics, eventName, eventData);
    },
    openContactMeForm() {
      this.logAnalyticsEvent('initiatedContact');
    },
    sendMail() {
      if(this.contact && this.contact.name && this.contact.subject && this.contact.content) {
        var finalTitle = "Portfolio contact request (" + this.contact.name + "): " + this.contact.subject;
        window.location = "mailto:ramonpeek@hotmail.com?subject=" + finalTitle + "&body=" + this.contact.content;
      }
    }
}
}
</script>
<style lang="scss" scoped>
.profile-picture {
  position: absolute;
  top: -30px; /* Adjust this value as needed */
  left: 10px; /* Adjust this value as needed */
  z-index: 1;
  border: 2px solid white; /* Optional: Add a border to the profile picture */
  border-radius: 50%; /* Optional: Make the profile picture circular */
}
</style>

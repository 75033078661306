<template>
    <v-card class="rounded-xl py-1 px-1">
        <v-card-title>
            Active certifications
        </v-card-title>
        <v-list lines="two">
            <v-list-item class="rounded-lg" @click="goToExternalLink(certification.externalLink)"
            v-for="certification in certifications.sort((a, b) => a.order - b.order).slice(certifications.length - 4, certifications.length)" :key="certification.identifier"
                :title="certification.identifier + ' - ' + certification.title"
                :prepend-avatar="certification.logoUrl"
                :subtitle="certification.achievedAt ? new Date(certification.achievedAt.seconds * 1000).toLocaleString('en-US', {
                            month: 'long',
                            year: 'numeric'
                            }) : ''"
                            :append-icon="certification.externalLink ? 'mdi-link-variant' : 'mdi-lock-outline'"
            >
            </v-list-item>
        </v-list>

    </v-card>
</template>

<script>
import { collection, onSnapshot } from "firebase/firestore"; 
import { getDownloadURL, ref as storageRef } from 'firebase/storage';

export default {
name: 'EducationExperience',
data: () => ({
    certifications: [],
}),
async mounted() {
    onSnapshot(collection(this.$firestore, "certifications"), (querySnapshot) => { 
      this.certifications = [];
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        if(data.logoFileName) {
          var fileRef = storageRef(this.$storage, 'certifications/' + data.logoFileName);
          getDownloadURL(fileRef).then(url => {
            data["logoUrl"] = url;
          }).finally(() => {
            this.certifications.push(data);
          });
        }else{
          this.certifications.push(data);
        }
      });
    });
},
methods: {
    goToExternalLink(externalLink) {
        if(externalLink) {
            window.open(externalLink).focus()
        }
    }
}
}
</script>
<style lang="scss" scoped>

</style>

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLnhEQ0RsoDuG0-OVbwNiJXL5tuVvH3o0",
  authDomain: "portfolio-e39dc.firebaseapp.com",
  projectId: "portfolio-e39dc",
  storageBucket: "portfolio-e39dc.appspot.com",
  messagingSenderId: "190611966935",
  appId: "1:190611966935:web:332e06de2fe1c5558f1992",
  measurementId: "G-3LLSZR6KV1"
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const firestore = getFirestore(); // Add this line to initialize Firestore
export const analytics = getAnalytics();
export const storage = getStorage();

export default {
  install: (app) => {
    app.config.globalProperties.$firebase = app;
    app.config.globalProperties.$firestore = firestore;
    app.config.globalProperties.$analytics = analytics;
    app.config.globalProperties.$storage = storage;
  }
}
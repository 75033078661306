<template>
    <v-card class="rounded-xl py-1 px-1">
        <v-card-title>
            Work experience
        </v-card-title>
        <v-list lines="two">
            <div v-for="experience in orderedExperiences" :key="experience.index">
                <v-list-item class="rounded-lg mb-0 pb-0"
                :title="experience.subtitle"
                :prepend-avatar="experience.logoUrl"
                :subtitle="experience.title"
                >
                </v-list-item>
                <div style="margin-left: 72px; margin-top: -7px;">
                    <small class="text-grey-lighten-1">
                        {{ formatDate(experience.startDate?.toDate()) + ' - ' + formatDate(experience.endDate?.toDate()) + ' (' + durationText(experience.startDate?.toDate(), experience.endDate?.toDate()) + ')' }}
                    </small>
                </div>
            </div>
        </v-list>
    </v-card>
</template>

<script>
import { collection, onSnapshot } from "firebase/firestore"; 
import { getDownloadURL, ref as storageRef } from 'firebase/storage';

export default {
name: 'WorkExperience',
data: () => ({
    experiences: []
}),
async mounted() {
    onSnapshot(collection(this.$firestore, "experiences"), (querySnapshot) => {
        this.experiences = [];
        querySnapshot.forEach((doc) => {
          var data = doc.data();
          if(data.logoFileName) {
            var fileRef = storageRef(this.$storage, 'experiences/' + data.logoFileName);
            getDownloadURL(fileRef).then(url => {
              data["logoUrl"] = url;
            }).finally(() => {
              this.experiences.push(data);
            });
          }else{
            this.experiences.push(data);
          }
        });
      });
    },
    computed: {
        orderedExperiences() {
            if(this.experiences && this.experiences.length) {
                return [...this.experiences].sort((a, b) => b.startDate - a.startDate).slice(this.experiences.length - 4, this.experiences.length);
            }
            return [];
        }
    },
    methods: {
        formatDate(date) {
      if(date == null || date == undefined)
        return "Present"
      const options = { year: 'numeric', month: 'short' };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
      return formattedDate;
    },
    durationText(date1, date2) {
      if(date1 == null || date1 == undefined) date1 = new Date();
      if(date2 == null || date2 == undefined) date2 = new Date();

      var diff = (date2.getTime() - date1.getTime()) / 1000;
      diff /= (60 * 60 * 24 * 7 * 4);
      var monthsDifference = Math.abs(Math.round(diff));
      if(monthsDifference < 12) {
        if(monthsDifference == 1) {
          return monthsDifference + " month";
        }else{
          return monthsDifference + " months";
        }
      }else{
        if(monthsDifference >= 12 && monthsDifference < 24) {
          return Math.abs(Math.round(monthsDifference / 12)) + " year";
        }else{
          return Math.abs(Math.round(monthsDifference / 12)) + " years";
        }
      }
    }
    }
}
</script>
<style lang="scss" scoped>

</style>

<template>
    <v-card class="rounded-xl py-1 px-1">
        <v-card-title>
            My expertises
        </v-card-title>
        <v-sheet class="pa-2 fill-height mb-2" v-for="expertise in expertises" :key="expertise.index">
            <div class="d-flex mb-1">
                <v-chip color="primary">
                <v-icon :icon="expertise.icon" class="ml-1 mr-1"></v-icon>
                </v-chip>
                <p class="font-weight-bold pt-1 pl-3">{{ expertise.title }}</p>
            </div>
            <div class="d-flex flex-column justify-space-between" style="height: calc(100% - 35px)">
                <small class="text-grey-darken-1">{{ expertise.shortDescription }}</small>
            </div>
        </v-sheet>
    </v-card>
</template>

<script>
import { collection, onSnapshot } from "firebase/firestore"; 

export default {
name: 'ExpertiseExperience',
data: () => ({
    expertises: [],
}),
async mounted() {
    onSnapshot(collection(this.$firestore, "expertises"), (querySnapshot) => {
      this.expertises = [];
      querySnapshot.forEach((doc) => {
        this.expertises.push(doc.data());
      });
    });
},
methods: {
    goToExternalLink(externalLink) {
        if(externalLink) {
            window.open(externalLink).focus()
        }
    }
}
}
</script>
<style lang="scss" scoped>

</style>

<template>
    <h2 class="text-grey-darken-3 ma-2 mt-0">Awesome projects and features</h2>

    <v-fade-transition appear>
          <v-expansion-panels class="mt-1" v-if="projects && projects.length > 0">
            <v-expansion-panel class="rounded-xl" v-for="project in projects.sort((a, b) => a.order - b.order)" :key="project.title" @click="logAnalyticsEvent('projectInteraction', {
              project: project.title,
              openedAt: new Date().toISOString()
            })">
              <v-expansion-panel-title>
                <v-card v-if="project.thumbnailUrl" class="project-display-image" color="grey-darken-3" :style="{backgroundImage: 'url(' + project.thumbnailUrl + ')' }"></v-card>
                <div class="ml-4 project-display-content" style="margin-top: -10px;">
                  <p class="text-button" v-if="project.title">{{ project.title }}</p>
                  <div style="margin-top: -5px;">
                    <small v-if="project.stakeholder" class="text-grey-darken-1">{{ project.stakeholder }}</small>
                    <small v-if="project.stakeholder && project.dateTime" class="text-grey-darken-1 ml-1 mr-1">•</small>
                    <small v-if="project.dateTime" class="text-grey-darken-1">{{ new Date(project.dateTime.seconds * 1000).toLocaleString('en-US', {
                        month: 'long',
                        year: 'numeric'
                        }) }}</small>
                  </div>
                  <div class="hidden-sm-and-down" v-if="project.tags && project.tags.length">
                    <v-chip v-for="tag in project.tags.slice(0, 8)" :key="tag.index" color="primary" class="mt-2 mr-2">
                      {{ tag }}
                    </v-chip>
                    <v-chip v-if="project.tags && project.tags.length > 8" color="primary" class="mt-2 mr-2">+{{ project.tags.length - 8 }} more</v-chip>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-carousel hide-delimiters progress="primary" class="mt-3 mb-3" style="width:100%;" v-if="project.media && project.media.length">
                      <v-carousel-item v-for="(media, i) in project.media.sort((a, b) => a.order - b.order)" :key="i" cover class="pt-3">
                            <v-card variant="tonal" v-if="media.type == 0" class="carousel-image" :style="{backgroundImage: 'url(' + media.mediaUrl + ')'}" style="border-radius: 5px;"></v-card>
                            <v-card variant="tonal" v-else-if="media.type == 1" style="height: 100%; border-radius:5px;">
                              <video class="carousel-video" autoplay muted>
                                <source :src="media.mediaUrl">
                              </video>
                            </v-card>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                  <v-col cols="12">
                    <div v-if="project?.description" class="mt-2 pa-4">
                      {{ project.description }}
                    </div>
                  </v-col>
                </v-row>

              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-fade-transition>
</template>

<script>
import { collection, onSnapshot } from "firebase/firestore"; 
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
name: 'ProjectExperience',
data: () => ({
    projects: [],
}),
methods: {
    logAnalyticsEvent(eventName, eventData) {
      logEvent(this.analytics, eventName, eventData);
    },
},
async mounted() {
    this.analytics = getAnalytics();

    //Load project data
    onSnapshot(collection(this.$firestore, "projects"), (querySnapshot) => {
      this.projects = [];
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        if(data.thumbnailFileName) {
          var folderName = doc.id;
          var fileRef = storageRef(this.$storage, 'projects/' + folderName + "/" + data.thumbnailFileName);
          getDownloadURL(fileRef).then(url => {
            data["thumbnailUrl"] = url;
            if(data.media && data.media.length) {
              data.media.forEach((media) => {
                var mediaFileRef = storageRef(this.$storage, 'projects/' + folderName + '/media/' + media.fileName);
                getDownloadURL(mediaFileRef).then(mediaUrl => {
                  media["mediaUrl"] = mediaUrl;
                });
              });
            }
          }).finally(() => {
            this.projects.push(data);
          });
        }else{
          this.projects.push(data);
        }
      });
    })
}
}
</script>
<style lang="scss" scoped>

</style>
